import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    meta: {
      title: '首页'
    },
  },
  {
    path: '/applycase',
    name: 'ApplyCase',
    component: () => import('../views/ApplyCase.vue'),
    meta: {
      title: '案件申请'
    },
  },
  {
    path: '/casedetail',
    name: 'CaseDetail',
    component: () => import('../views/CaseDetail.vue'),
    meta: {
      title: '案件详情'
    },
  }, 
  {
    path: '/pdflook',
    name: 'PdfLook',
    component: () => import('../views/PdfLook.vue'),
    meta: {
      title: '预览PDF'
    },
  },
  {
    path: '/changeMobile',
    name: 'ChangeMobile',
    component: () => import('../views/ChangeMobile.vue'),
    meta: {
      title: '更改手机号'
    },
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue'),
    meta: {
      title: '更改密码'
    },
  },
  { 
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登录'
    },  
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: {
      title: '注册'
    },
  },
  // { 
  //   path: '/',
  //   name: 'Register',
  //   component: () => import('../views/Register.vue'),
  //   meta: {
  //     title: '注册'
  //   },  
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
